<template>
	<div class="store-page__container" :class="storeTheme" ref="storeHeader">
		<div ref="pageWrapperRef" v-if="isLoaded" class="white_page_wrapper store-theme-page" :class="storeTheme" :style="deviceStyle">
			<div v-if="isDesktop" class="store-theme" :class="storeTheme">
				<Header
					:full_name="user.full_name"
					:username="user.username"
					:userBio="user.bio"
					:user_image="$optimizeImage(user.user_image, { width: 500 })"
					:storeTheme="storeTheme"
				/>
			</div>
			<NotificationsToast />
			<div class="store-content__width" ref="storeContent">
				<transition-group name="fade-transform" mode="out-in">
					<div v-if="!isCheckout" key="basic" class="page-container relative">
						<transition-group name="fade-transform" tag="div" mode="out-in">
							<template>
								<Banner
									class="mb-3 pages-block"
									:title="product.title"
									:image="$optimizeImage(product.image, { width: 500 })"
									:background-color="userPrimaryColor"
									:show-back="showBackButton"
									@back="goBack"
									key="banner"
								/>
								<div
									v-if="isStoreLinkSite && isStockManaged"
									class="product-stock px-5-mod"
									:class="{ 'low-stock': isLowStock, 'out-of-stock': !isInStock }"
									key="stock_quantity"
								>
									{{ quantity_text }}
								</div>
								<Price
									v-if="(isStoreFunnel || isInStock) && !paymentDisabled"
									class="pages-block px-5-mod mb-3"
									:currency="user.data.currency"
									:amount="product.price.amount"
									:sale-amount="product.price.sale_amount_available ? product.price.sale_amount : 0"
									:accent-color="userPrimaryColor"
									:interval="product.price.interval"
									key="price"
								/>
								<div
									v-if="!((isStoreFunnel || isInStock) && !paymentDisabled) && !hasNewProductComingSoon"
									class="payment-restricted"
									key="payment_restricted"
								>
									<Icon class="icon-warning" name="warning" :scale="1" :yoffset="-2" />
									<span>Product coming soon!</span>
								</div>
								<div v-if="paymentDisabledHidden && isPaid" key="coming-soon">
									<div class="coming-soon-container">
										<p class="coming-soon-header">Product Coming Soon</p>
										<p class="coming-soon-body">The product will be launching soon. Hang tight!</p>
									</div>
								</div>
								<div key="line-separator-1" class="line-separator"></div>
								<TipTapViewer
									class="pages-block px-5-mod description"
									:value="product.description"
									:accent-color="userPrimaryColor"
									key="description"
								></TipTapViewer>
								<OrderBump
									v-if="isOrderBumpAvailable && (canShowStripe || canShowPaypal)"
									v-model:add_upsell_to_order="add_upsell_to_order"
									:secondBuiltInProduct="secondBuiltInProduct"
									:user="user"
									:userPrimaryColor="userPrimaryColor"
									:isPageProcessing="isPageProcessing"
									key="order-bump"
								/>
								<client-only>
									<UserReviews
										v-if="reviews && reviews.length"
										:reviews="reviews"
										class="pages-block"
										key="user-reviews"
										@modalOpen="handleReviewModalOpen"
									/>
								</client-only>
								<div key="line-separator-3" class="line-separator"></div>
								<div
									v-if="!page.data.bottom_title && page.data.payment_title && !paymentDisabledHidden"
									class="pages-block payments px-5-mod text-center"
									key="bottom-title-payment-title"
								>
									<h4>
										<span :style="{ color: userPrimaryColor }">{{ page.data.payment_title }}</span>
									</h4>
								</div>
								<div
									v-if="page.data.bottom_title && !paymentDisabledHidden"
									class="pages-block payments px-5-mod text-center"
									key="bottom-title-payment-title"
								>
									<h4>
										<span :style="{ color: userPrimaryColor }">{{ page.data.bottom_title }}</span>
									</h4>
								</div>
							</template>
							<template>
								<countdown-timer
									key="countdown"
									:countdownTimer="page.data.countdown_timer ? page.data.countdown_timer : {}"
									:page-id="paymentPageId"
								/>
							</template>

							<client-only>
								<component
									v-bind:is="calendarInstance"
									v-if="page.type === 'meeting' && fcpComplete && !paymentDisabledHidden"
									class="pages-block orient-horizontal px-5-mod"
									:primary-color="userPrimaryColor"
									:slug="product.meeting.slug"
									:username="user.username"
									:pre-selected-date="preSelectedValue"
									@time-selected="bookingTimeSelected"
									key="calendar"
									ref="calendar"
								/>
								<component
									v-bind:is="webinarCalendarInstance"
									v-if="page.type === pageTypes.WEBINAR && fcpComplete && !paymentDisabledHidden"
									class="pages-block orient-horizontal px-5-mod"
									:primary-color="userPrimaryColor"
									:slug="product.webinar.slug"
									:username="user.username"
									:pre-selected-session="preSelectedValue"
									@time-selected="sessionTimeSelected"
									key="webinar-calendar"
									ref="webinar-calendar"
								/>
							</client-only>
							<template v-if="!paymentDisabledHidden">
								<div key="line-separator-4" class="line-separator"></div>
								<Form
									ref="inputForm"
									:show-base="true"
									:show-message="showMessageInForm"
									:questions="questions"
									:cached-question-answers="cachedQuestionAnswers"
									:fcp-complete="fcpComplete"
									:disabled="!isInStock || paymentDisabled"
									:autofill-question-answers="autofillQuestionAnswers"
									:showAddress="salesTaxEnabled"
									@setFanDetails="setFanDetails"
									@setAnswers="setAnswers"
									@setNote="setNote"
									@formIsValid="setFanDetailsValid"
									class="pages-block"
									:key="`form`"
								/>
							</template>
						</transition-group>
						<!-- base content -->
						<transition name="fade-transform" appear mode="out-in">
							<div
								v-if="grossAmountPreDiscount && product.price.discount_codes_available && isInStock && !paymentDisabledHidden"
								:key="activePage"
								class="pages-block px-5-mod mb-5"
								key="discount-code"
							>
								<h3 class="mb-3 section-title">Discount Code</h3>
								<div
									v-if="discount_code_accepted"
									class="discount-code-wrapper"
									:class="{ 'content-disabled': !isPaymentGatewayDefined }"
								>
									<p>
										<span v-if="!discount_duration" class="discount-successful" :style="{ color: userPrimaryColor }"
											>Yay! You just saved {{ $formatCurrency(discount_amount, user.data.currency) }}
										</span>
										<span v-else class="discount-successful" :style="{ color: userPrimaryColor }"
											>Yay! {{ ((discount_amount / grossAmountPreDiscount) * 100).toFixed(0) }}% off for
											{{ discount_duration }} month(s)!
										</span>
										<br />
										<span class="subtext">Coupon Applied</span>
									</p>
									<span class="ic-close" @click="clearDiscountCode"><Icon class="icon-svg" name="close" :scale="1" /> </span>
								</div>
								<div v-else class="discount-code-wrapper input-box" :class="{ 'content-disabled': !isPaymentGatewayDefined }">
									<input
										v-model="discount_code"
										placeholder="Enter promo code here"
										@keydown.enter="applyDiscountCode"
										@input="discount_error = ''"
									/>
									<span v-if="discount_error && discount_code" class="ic-close" @click="clearDiscountCode"
										><Icon class="icon-svg" name="close" :scale="1"
									/></span>
									<HollowDotsSpinner
										v-else-if="discount_processing"
										:animation-duration="1000"
										:dot-size="12"
										class="d-flex align-items-center"
										:dots-num="3"
										:color="userPrimaryColor"
										style="margin-top: 8px"
									/>
									<button v-else @click="applyDiscountCode">Apply</button>
								</div>
								<small class="text-danger">{{ discount_error }}</small>
							</div>
						</transition>
						<!-- discount Code -->
						<transition-group name="fade-transform" appear mode="out-in" v-show="!!terms_and_conditions_enabled">
							<div key="terms-and-conditions" class="px-5">
								<TermsAndConditions
									:terms-and-conditions="terms_and_conditions"
									:user-primary-color="userPrimaryColor"
									@sendAnalytic="sendAnalytic"
									@stateChanged="termsAndConditionUpdated"
								/>
							</div>
						</transition-group>
						<transition-group name="fade-transform" appear mode="out-in" v-if="!salesTaxEnabled || !isPaid">
							<div class="pages-block px-5-mod relative" key="total-block" v-if="showTotal">
								<div class="amount-wrapper">
									<div v-if="strikeThruPriceNearCheckout && grossMultiProductAmount > 0" class="strikethru-container">
										<span class="d-flex justify-content-between align-items-center w-100 price-component">
											<span class="subtotal pr-2">Subtotal :</span>
											<div class="subtotal-am pl-2" :style="{ color: userPrimaryColor }">
												{{
													$formatCurrency(
														product.price.amount +
															parseFloat(
																this.secondBuiltInProduct && this.add_upsell_to_order
																	? this.secondProductGrossAmount
																	: 0
															),
														user.data.currency,
														'en-US',
														true
													)
												}}
											</div>
										</span>
										<span class="d-flex justify-content-between align-items-center w-100">
											<span class="subtotal pr-2">Discount :</span>
											<div class="subtotal-am pl-2" :style="{ color: '#cad1e1' }">
												-
												{{
													$formatCurrency(product.price.amount - grossMultiProductAmount, user.data.currency, 'en-US', true)
												}}
											</div>
										</span>
									</div>
									<span class="d-flex justify-content-between align-items-center divide-bar w-100">
										<span class="total pr-2">Total :</span>
										<div v-if="!isInStock" class="total-am pl-2" :style="{ color: userPrimaryColor }">Sold Out</div>
										<div v-else-if="grossMultiProductAmount > 0" class="total-am pl-2" :style="{ color: userPrimaryColor }">
											{{ $formatCurrency(grossMultiProductAmount, user.data.currency, 'en-US', true) }}
										</div>
										<div v-else class="total-am pl-2" :style="{ color: userPrimaryColor }">FREE</div>
									</span>
								</div>
								<div v-if="discount_duration" key="duration-block" class="text-right">
									for {{ discount_duration }} month(s), {{ $formatCurrency(grossAmountPreDiscount, user.data.currency) }} afterwards
								</div>
								<div v-if="product.price.interval && product.price.membership_duration" key="duration-block" class="text-right">
									Payments will automatically end after {{ product.price.membership_duration }} period(s)
								</div>
							</div>
						</transition-group>
						<transition-group name="fade-transform" appear mode="out-in" v-else>
							<SalesTax
								key="total-block-with-tax"
								:userPrimaryColor="userPrimaryColor"
								:nonDiscountedPrice="
									product.price.amount +
									parseFloat(this.secondBuiltInProduct && this.add_upsell_to_order ? this.secondProductGrossAmount : 0)
								"
								:grossAmountPreDiscountCode="grossAmountPreDiscount"
								:discount_duration="discount_duration"
								:product="product"
								:currency="user.data.currency"
								:grossMultiProductAmount="grossMultiProductAmount"
								:salesTax="salesTax"
							/>
						</transition-group>
						<!-- total -->

						<!-- If free product -->
						<div v-if="!isPaid">
							<transition-group tag="div" name="fade-transform-long" appear mode="out-in" key="mainPurchase" class="px-5 py-5">
								<div class="pages-block" key="submit-block">
									<button
										type="submit"
										class="confirm-button"
										id="store-page-submit-button"
										:style="userBackground"
										:disabled="isPageProcessing || !isInStock"
										@click="triggerSubmit()"
									>
										<span class="text-center abos-loader">
											<HollowDotsSpinner
												v-if="isPageProcessing"
												:animation-duration="1000"
												:dot-size="12"
												class="d-flex align-items-center"
												:dots-num="3"
												color="#ffffff"
											/>
											<span v-else class="white-clr">{{ page.data.submit_title || 'PURCHASE' }}</span>
										</span>
										<div class="d-none optional-icon" />
									</button>
								</div>
							</transition-group>
						</div>

						<!-- New Stripe payment Element-->
						<div v-if="!paymentDisabled">
							<div v-if="isPaid">
								<div class="stan-flexible-payment-options px-5" v-if="isStanFlexiblePaymentAvailable">
									<button
										type="button"
										class="one-time-payment-button w-100"
										:class="{ selected: !isStanFlexiblePaymentSelected }"
										:style="userBackground"
										@click="oneTimePayment()"
									>
										<span class="text-center abos-loader d-flex justify-content-between">
											<span class="white-clr">Pay Now</span>
											<span class="white-clr amount-due">{{
												$formatCurrency(oneTimeAmount, user.data.currency, 'en-US', true)
											}}</span>
										</span>
										<div class="d-none optional-icon" />
									</button>
									<button
										type="button"
										class="one-time-payment-button w-100"
										:class="{ selected: isStanFlexiblePaymentSelected }"
										:style="userBackground"
										@click="stanFlexiblePayment()"
									>
										<span class="text-center abos-loader d-flex justify-content-between">
											<span class="white-clr">Payment Plan</span>
											<span class="white-clr amount-due">{{
												$formatCurrency(paymentPlanAmount, user.data.currency, 'en-US', true)
											}}</span>
										</span>
										<div class="d-none optional-icon" />
									</button>
								</div>
								<div class="one-time-payment-block" :class="{ visible: !isStanFlexiblePaymentSelected }">
									<transition-group
										v-if="!paymentDisabled"
										tag="div"
										name="fade-transform-long"
										appear
										mode="out-in"
										v-show="showPaymentGateways || false"
										class="px-5"
									>
										<div key="payment-methods" class="mb-5 mt-5">
											<HollowDotsSpinner
												v-if="!paymentGatewaysReady"
												class="d-flex align-items-center"
												:animation-duration="1000"
												:dot-size="14"
												:dots-num="3"
												:color="userPrimaryColor"
											/>

											<div
												key="payment-method-card-component-wrapper stripe-payment-element"
												v-if="canShowStripe && !usePaymentOrchestration"
											>
												<StripePaymentElement
													ref="stripePaymentElement"
													class="pages-block invert boxed"
													:username="user.username"
													:page-id="paymentPageId"
													:fcp-complete="paymentCardViewed"
													:is-paid="isTotalPaid && isInStock"
													:currency="user.data.currency || 'USD'"
													:primary-color="userPrimaryColor"
													:is-form-submitting="isPageProcessing"
													:resell-rights-uuid="finalResellRightsUuid"
													:affiliate-code="affiliateCode"
													:theme="store.data.theme"
													:product="page.data.product"
													:trigger-submit="triggerSubmit"
													:analyticsProps="analyticsProps"
													:fan-name="fanDetails.name"
													:fan-email="fanDetails.email"
													:store="store"
													:show-wallet="showWallet"
													:stripe-processing-account="stripeProcessingAccount"
													:stripeProcessingAccountType="stripeProcessingAccountType"
													@gatewayAvailable="handleGateway"
													@paymentInit="paymentInitialised = true"
													@paymentData="paymentDataReceived"
													@paymentSuccess="paymentElementSuccess"
													@paymentError="paymentError"
													@paymentCancel="paymentCancel"
												/>
											</div>
											<div key="payment-method-card-component-wrapper stripe-payment-element" v-if="usePaymentOrchestration">
												<StripePaymentElement2
													ref="stripePaymentElement2"
													class="pages-block invert boxed"
													:fcp-complete="paymentCardViewed"
													:is-form-submitting="isPageProcessing"
													:stripe-account-id="stripeAccountId"
													:amount="totalAmount"
													:currency="user.data.currency || 'USD'"
													:show-wallet="showWallet"
													:username="user.username"
													:fan-name="fanDetails.name"
													:fan-email="fanDetails.email"
													:theme="store.data.theme"
													:store="store"
													@gatewayAvailable="handleGateway"
													@paymentInit="paymentInitialised = true"
													@paymentSuccess="paymentElementSuccess"
													@paymentError="paymentError"
												/>
											</div>
										</div>
									</transition-group>
									<transition-group
										tag="div"
										name="fade-transform-long"
										appear
										mode="out-in"
										v-if="(!showPaymentGateways || canShowStripe) && !paymentDisabledHidden"
										class="px-5-mod"
									>
										<div v-if="canShowStripe && !usePaymentOrchestration" class="pages-block" key="submit-block">
											<button
												type="submit"
												class="confirm-button"
												id="store-page-submit-button"
												:style="userBackground"
												:disabled="
													isPageProcessing ||
													!isInStock ||
													paymentDisabled ||
													(!isStripeConnect && !fromValidAffiliateShareLink && !isProcessingMarketplace)
												"
												@click="triggerSubmit('stripe')"
											>
												<span class="text-center abos-loader">
													<HollowDotsSpinner
														v-if="isPageProcessing"
														:animation-duration="1000"
														:dot-size="12"
														class="d-flex align-items-center"
														:dots-num="3"
														color="#ffffff"
													/>
													<span v-else class="white-clr">{{ page.data.submit_title || 'PURCHASE' }}</span>
												</span>
												<div class="d-none optional-icon" />
											</button>
										</div>
										<div v-if="usePaymentOrchestration" class="pages-block" key="submit-block">
											<button
												type="submit"
												class="confirm-button"
												id="store-page-submit-button"
												:style="userBackground"
												:disabled="isPageProcessing || !isInStock || paymentDisabled"
												@click="triggerSubmit('stripe2')"
											>
												<span class="text-center abos-loader">
													<HollowDotsSpinner
														v-if="isPageProcessing"
														:animation-duration="1000"
														:dot-size="12"
														class="d-flex align-items-center"
														:dots-num="3"
														color="#ffffff"
													/>
													<span v-else class="white-clr">{{ page.data.submit_title || 'PURCHASE' }}</span>
												</span>
												<div class="d-none optional-icon" />
											</button>
										</div>
									</transition-group>
									<transition-group
										v-if="!paymentDisabled"
										tag="div"
										name="fade-transform-long"
										appear
										mode="out-in"
										v-show="showPaymentGateways"
										class="px-5"
									>
										<div key="payment-separator" v-if="hasMultiplePaymentGateway && canShowStripe" class="separator">or</div>
										<div key="payment-methods" class="mb-5" style="margin-top: 10px">
											<HollowDotsSpinner
												v-if="!paymentGatewaysReady"
												class="d-flex align-items-center"
												:animation-duration="1000"
												:dot-size="14"
												:dots-num="3"
												:color="userPrimaryColor"
											/>
											<div
												v-for="(paymentGateway, index) in paymentGateways"
												:key="`payment-method-${paymentGateway.name}-component-wrapper`"
												v-if="user.connected_payment_methods[paymentGateway.integration]"
											>
												<div
													:class="`payment-method-${paymentGateway.name}-component-wrapper mb-4`"
													@mouseenter="validateForm"
													v-if="paymentGateway.canActivate(paymentGateway.data)"
													@click.self="checkShowSubmitError()"
												>
													<component
														v-show="paymentGateway.active"
														:style="{ pointerEvents: !isFanDetailsValid ? 'none' : 'auto' }"
														v-bind:is="paymentGateway.component"
														:ref="`${paymentGateway.name}Payment`"
														:product="page.data.product"
														:username="user.username"
														:page-id="paymentPageId"
														:store-slug="store.slug"
														:fcp-complete="paymentCardViewed"
														:is-paid="isTotalPaid && isInStock"
														:amount="totalAmount"
														:currency="user.data.currency || 'USD'"
														:trigger-submit="triggerSubmit"
														:primary-color="userPrimaryColor"
														:purchase-intent-payload="purchaseIntentPayload"
														:is-stripe-connect="isStripeConnect"
														:is-form-submitting="isPageProcessing"
														:analyticsProps="analyticsProps"
														:isProductRecurring="isProductRecurring"
														@paymentInit="paymentInitialised = true"
														@gatewayAvailable="handleGateway"
														@paymentData="paymentDataReceived"
														@paymentSuccess="paymentElementSuccess"
														@paymentError="paymentError"
														@paymentCancel="paymentCancel"
													/>
												</div>
											</div>
										</div>
									</transition-group>
								</div>
								<div
									class="payment-plans-payment-block"
									:class="{ visible: isStanFlexiblePaymentSelected }"
									v-if="isStanFlexiblePaymentAvailable"
								>
									<transition-group
										v-if="!paymentDisabled"
										tag="div"
										name="fade-transform-long"
										appear
										mode="out-in"
										v-show="showPaymentGateways"
										class="px-5"
									>
										<div key="payment-methods" class="mb-5 mt-5">
											<HollowDotsSpinner
												v-if="!paymentGatewaysReady"
												class="d-flex align-items-center"
												:animation-duration="1000"
												:dot-size="14"
												:dots-num="3"
												:color="userPrimaryColor"
											/>
											<div class="flexible-pay-banner">
												<span> {{ stanFlexiblePaymentHeader }} </span>
											</div>
											<div key="payment-method-card-component-wrapper stripe-payment-element" v-if="canShowStripe">
												<StanFlexiblePayment
													ref="stanFlexiblePaymentElement"
													class="pages-block invert boxed"
													:username="user.username"
													:page-id="paymentPageId"
													:fcp-complete="paymentCardViewed"
													:is-paid="isTotalPaid && isInStock"
													:currency="user.data.currency || 'USD'"
													:primary-color="userPrimaryColor"
													:is-form-submitting="isPageProcessing"
													:resell-rights-uuid="finalResellRightsUuid"
													:affiliate-code="affiliateCode"
													:theme="store.data.theme"
													:product="page.data.product"
													:trigger-submit="triggerSubmit"
													:analyticsProps="analyticsProps"
													:fan-name="fanDetails.name"
													:fan-email="fanDetails.email"
													:store="store"
													:stripe-processing-account="stripeProcessingAccount"
													:stripeProcessingAccountType="stripeProcessingAccountType"
													@gatewayAvailable="handleGateway"
													@paymentInit="paymentInitialised = true"
													@paymentData="paymentDataReceived"
													@paymentSuccess="paymentElementSuccess"
													@paymentError="paymentError"
													@paymentCancel="paymentCancel"
												/>
											</div>
										</div>
									</transition-group>
									<transition-group
										tag="div"
										name="fade-transform-long"
										appear
										mode="out-in"
										v-if="(!showPaymentGateways || canShowStripe || usePaymentOrchestration) && !paymentDisabledHidden"
										class="px-5-mod"
									>
										<div class="pages-block" key="submit-block">
											<button
												type="submit"
												class="confirm-button"
												id="store-page-submit-button"
												:style="userBackground"
												:disabled="
													isPageProcessing ||
													!isInStock ||
													paymentDisabled ||
													(!isStripeConnect && !fromValidAffiliateShareLink && !isProcessingMarketplace)
												"
												@click="triggerSubmit('stan-flexible-payment')"
											>
												<span class="text-center abos-loader">
													<HollowDotsSpinner
														v-if="isPageProcessing"
														:animation-duration="1000"
														:dot-size="12"
														class="d-flex align-items-center"
														:dots-num="3"
														color="#ffffff"
													/>
													<span v-else class="white-clr">{{ page.data.submit_title || 'PURCHASE' }}</span>
												</span>
												<div class="d-none optional-icon" />
											</button>
										</div>
									</transition-group>
								</div>
							</div>
						</div>
						<ExternalLinks class="privacy-policy" />
						<!-- card payment -->
					</div>
					<Summary
						v-else
						class="pages-block body-wrapper relative"
						:banner-image="$optimizeImage(store.data.checkout_image, { width: 500 })"
						:user-primary-color="userPrimaryColor"
						:products="purchasedProducts"
						key="summary"
					/>
				</transition-group>
			</div>
		</div>
		<div v-else class="loader-wrapper">
			<div class="loader-block">
				<HollowDotsSpinner
					class="d-flex align-items-center"
					:animation-duration="1000"
					:dot-size="16"
					:dots-num="3"
					:color="userPrimaryColor"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import { debounce as _debounce } from 'lodash'
	import { getOptimizeImageUrlSync } from '~/stan-vue-shared/components/OptimizationImageTools'
	import Header from '~/stan-vue-shared/components/Header'
	import Banner from '~/stan-vue-shared/components/Banner'
	import TipTapViewer from '~/stan-vue-shared/components/TipTapViewer'
	import UserReviews from '~/stan-vue-shared/components/UserReviews'
	import StripePaymentElement from '~/stan-vue-shared/components/StripePaymentElement'
	import StripePaymentElement2 from '~/stan-vue-shared/components/StripePaymentElement2'
	import PaypalPayment from '~/components/PaypalPayment'
	import PaypalPayment2 from '~/components/PaypalPayment2'
	import StanFlexiblePayment from './StanFlexiblePayment'
	import TermsAndConditions from '~/components/TermsAndConditions'
	import Price from '~/stan-vue-shared/components/Price'
	import Video from '~/stan-vue-shared/components/Video'
	import Form from '~/stan-vue-shared/components/Form'
	import ExternalLinks from '~/stan-vue-shared/components/ExternalLinks'
	import HollowDotsSpinner from '~/stan-vue-shared/components/HollowDotsSpinner'
	import CountdownTimer from '~/stan-vue-shared/components/CountdownTimer.vue'
	import { debounce } from '~/plugins/debounce'
	import PageComponentMixin from '~/mixins/PageComponentMixin.js'
	import Icon from '~/stan-vue-shared/components/icons/Icon.vue'
	import NotificationsToast from './NotificationsToast'
	import { PAGE_TYPES, PAGE_ERROR_CODES } from '~/constants'
	import SalesTax from '~/components/SalesTax'
	import OrderBump from '~/stan-vue-shared/components/OrderBump'
	import { CODE_TO_ERROR_MESSAGE } from '../constants'
	import { isEmpty } from '../stan-vue-shared/components/utils'

	const loadCaptcha = async site_key => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${site_key}`
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}
	export default {
		name: 'StorePage',
		mixins: [PageComponentMixin],
		components: {
			Header,
			Banner,
			TipTapViewer,
			UserReviews,
			StripePaymentElement,
			StripePaymentElement2,
			PaypalPayment,
			PaypalPayment2,
			StanFlexiblePayment,
			Form,
			Price,
			Video,
			ExternalLinks,
			HollowDotsSpinner,
			TermsAndConditions,
			Icon,
			NotificationsToast,
			SalesTax,
			OrderBump,
			CountdownTimer,
		},
		head() {
			const title = '@' + this.user.username
			const description = this.page.data.product.title
			const image = this.$optimizeImage(this.page.data.product.image, { width: 500 })

			const optimizeImageOptions = {
					format: 'webp',
					quality: 100,
					height: 433,
				}
			const optimizeImageSrc = getOptimizeImageUrlSync(image, { ...optimizeImageOptions }, true)

			return {
				title,
				meta: [
					{ hid: 'description', name: 'description', content: description },
					{ hid: 'og:title', property: 'og:title', content: title },
					{ hid: 'og:description', property: 'og:description', content: description },
					{ hid: 'og:image-webp', property: 'og:image', content: optimizeImageSrc },
					{ hid: 'og:image', property: 'og:image', content: image },
					{ hid: 'twitter:title', name: 'twitter:title', content: title },
					{ hid: 'twitter:description', name: 'twitter:description', content: description },
					{ hid: 'twitter:image-webp', name: 'twitter:image', content: optimizeImageSrc },
					{ hid: 'twitter:image', name: 'twitter:image', content: image },
				],
				link: [
					{ rel: 'icon', type: 'image/x-icon', href: this.$optimizeImage(this.user.user_image, { width: 16, height: 16, format: 'ico' }) },
				],
			}
		},
		props: {
			page: { type: Object, default: () => {} },
			storeObj: { type: Object, default: () => {} },
			user: { type: Object, default: () => {} },
			secondProduct: { type: Object, default: () => {} },
			affiliateCode: { type: String, default: undefined },
			resellRightsUuid: { type: String, default: undefined },
			redirectUsername: { type: String, default: undefined },
			showBackButton: { type: Boolean, default: true },
		},
		data() {
			return {
				isDesktop: false,
				analyticsProps: {},
				store: {},
				isCheckout: false,
				activePage: 0,
				updatedPaymentIntentId: '',
				paymentIntent: {},
				purchasedProducts: [],
				processingSecondProduct: false,
				purchasedPaymentIntents: [],
				discount_code: '',
				discount_processing: false,
				discount_code_accepted: false,
				discount_value: 0, // percentage
				discount_duration: 0,
				discount_error: '',
				paymentCardViewed: false,
				fanDetails: {},
				formMessageProducts: ['meeting', 'fulfillment'],
				recurringProducts: ['membership'],
				paymentCardPosition: 0,
				documentBottom: 0,
				add_upsell_to_order: false,
				bookingTime: false,
				bookingDuration: 0,
				sessionTimeSlot: undefined,
				loadAsynCcomponents: false,
				isFanDetailsValid: false,
				cachedQuestionAnswers: {},
				autofillQuestionAnswers: {},
				country: 'US',
				fcpComplete: false,
				auth_token: null,
				isTermsAndConditionChecked: false,
				formSubmitting: false,
				paymentInitialised: false,
				paymentGateways: [
					{
						name: 'paypal',
						integration: 'paypal',
						component: PaypalPayment,
						active: false,
						label: 'Paypal',
						immediateDelivery: true,
						canActivate: () => {
							return (
								!this.fromValidAffiliateShareLink &&
								!(this.page.data.product.price.interval && this.add_upsell_to_order) &&
								!this.usePaymentOrchestration
							)
						},
					},
					{
						name: 'paypal2',
						integration: 'paypal',
						component: PaypalPayment2,
						active: false,
						label: 'Paypal',
						immediateDelivery: true,
						canActivate: () => {
							return !!this.usePaymentOrchestration
						},
					},
				],
				activePaymentGatewayName: null,
				activePaymentGateway: null,
				isStripeConnect: undefined,
				paymentGatewaysInitialised: 0,
				secondPaymentDataUpdated: false,
				stripePrimaryColor: '',
				stripeTone1Color: '',
				secondBuiltInProduct: undefined,
				stripeTaxCapabilities: {},
				salesTax: {},
				gatewayRefLookup: {
					stripe: 'stripePaymentElement',
					stripe2: 'stripePaymentElement2',
					paypal: 'paypalPayment',
					paypal2: 'paypal2Payment',
					'stan-flexible-payment': 'stanFlexiblePaymentElement',
				},
				isStanFlexiblePaymentSelected: false, // Default to one time payment
				pageTypes: PAGE_TYPES,
				orderId: undefined,
				listenerAttached: false,
				scrollHandler: null,
			}
		},
		computed: {
			isPageProcessing() {
				return this.paymentInitialised || this.formSubmitting
			},
			isStockManaged() {
				return !!this.product?.inventory?.manage
			},
			stockQuantity() {
				return this.isStockManaged ? this.product.inventory.stock : undefined
			},
			isLowStock() {
				return this.stockQuantity > 0 && this.stockQuantity < 3
			},
			isInStock() {
				return this.stockQuantity === undefined || this.stockQuantity > 0
			},
			grossAmount() {
				let grossAmount = this.grossAmountPreDiscount

				if (this.discount_code && this.discount_code_accepted) {
					grossAmount = Math.round(grossAmount * ((100 - this.discount_value) / 100) * 100) / 100
				}
				return grossAmount
			},
			grossAmountPreDiscount() {
				let grossAmount = 0
				if (this.isStanFlexiblePaymentSelected) {
					grossAmount = this.product.price.stan_flexible_payment?.amount / this.product.price.stan_flexible_payment?.duration
				} else {
					grossAmount =
						this.product.price.sale_amount_available && this.product.price.sale_amount > 0
							? this.product.price.sale_amount
							: this.product.price.amount
				}
				return grossAmount
			},
			secondProductGrossAmount() {
				return this.secondBuiltInProduct.data.product.price.sale_amount_available &&
					this.secondBuiltInProduct.data.product.price.sale_amount > 0
					? this.secondBuiltInProduct.data.product.price.sale_amount
					: this.secondBuiltInProduct.data.product.price.amount
			},
			grossMultiProductAmount() {
				return (
					parseFloat(this.grossAmount) +
					parseFloat(this.secondBuiltInProduct && this.add_upsell_to_order ? this.secondProductGrossAmount : 0)
				)
			},
			salesTaxAmount() {
				if (this.salesTax && this.salesTax?.tax_breakdown?.amount) {
					if (this.isSalesTaxInclusive) {
						return this.grossMultiProductAmount * (this.salesTaxPercentage / (1 + this.salesTaxPercentage))
					}
					return this.grossMultiProductAmount * this.salesTaxPercentage
				}
				return 0
			},
			isSalesTaxInclusive() {
				return this.salesTax && this.salesTax?.tax_breakdown?.inclusive
			},
			salesTaxPercentage() {
				return (
					Number(
						this.salesTax && this.salesTax?.tax_breakdown?.tax_rate_details?.percentage_decimal
							? this.salesTax?.tax_breakdown?.tax_rate_details?.percentage_decimal
							: 0
					) / 100
				)
			},
			totalAmount() {
				if (this.isSalesTaxInclusive) {
					return this.grossMultiProductAmount
				}
				return this.grossMultiProductAmount + this.salesTaxAmount
			},

			showTotal() {
				if (this.grossAmount > 0 || !this.isInStock) return true
				return false
			},
			questions() {
				if (this.page.data.display_data?.blocks) {
					const formBlock = this.page.data.display_data.blocks.find(block => block.type === 'form')
					if (formBlock) {
						return formBlock.content.questions
					}
				}
				return this.product.questions || []
			},
			isStoreLinkSite() {
				return this.store.type === 'linksite'
			},
			isStoreFunnel() {
				return this.store.type === 'funnel'
			},
			isPaid() {
				return this.grossAmount > 0
			},
			isTotalPaid() {
				return this.grossMultiProductAmount > 0
			},
			paymentPageId() {
				if (!this.isPaid && this.isTotalPaid && this.secondBuiltInProduct) {
					return this.secondBuiltInProduct.page_id
				}
				return this.page.page_id
			},
			showMessageInForm() {
				return this.formMessageProducts.includes(this.page.type)
			},
			showPaymentGateways() {
				return this.isInStock && this.isTotalPaid
			},
			salesTaxEnabled() {
				return !!this.stripeTaxCapabilities?.data?.tax_enabled && !this.fromValidAffiliateShareLink && this.isPaid
			},
			isProductRecurring() {
				return !!this.page.data.product.price.interval || this.recurringProducts.includes(this.page.type)
			},
			reviews() {
				if (this.product.reviews && this.product.reviews.length) {
					return this.product.reviews
				}
				return []
			},
			purchaseIntentPayload() {
				const page = this.secondBuiltInProduct && this.processingSecondProduct ? this.secondBuiltInProduct : this.page
				const sendData = {
					page_id: page.page_id,
					transaction_origin: this.paymentIntent?.transaction_origin || 'user',
					charge_type: this.paymentIntent?.charge_type || 'destination',
					email: this.fanDetails.email,
					name: this.fanDetails.name,
					note: this.fanDetails.note,
					phone_number: this.fanDetails.phone_number,
					description: page.data.product.title + ' <> ' + this.fanDetails.name,
					payment_gateway: this.activePaymentGatewayName,
					stripe_processing_account_type: this.stripeProcessingAccountType,
				}
				if (this.finalResellRightsUuid) {
					sendData.resell_rights_uuid = this.finalResellRightsUuid
				} else if (this.affiliateCode) {
					sendData.affiliate_code = this.affiliateCode
					sendData.affiliated_username = this.$route.query?.su || this.$sessionStorage.getItem('LastVisitStore')
					sendData.affiliate_link = window.location.href
				}
				if (!isEmpty(this.tags)) sendData.tags = { ...this.tags }
				if (!isEmpty(this.trackingProps)) sendData.tags = { ...sendData.tags, ...this.trackingProps }
				if (this.isTotalPaid) {
					sendData.payment_intent_id = this.paymentIntent?.id
				}

				if (page.type === 'meeting' && this.bookingTime) {
					const startDateTime = new Date(this.bookingTime.date.replace(/-/g, '/') + ' ' + this.bookingTime.startTime)
					const endDateTime = new Date(startDateTime.getTime() + this.bookingDuration * 60000)
					sendData.meeting = {
						start: this.generateTimeString(startDateTime),
						end: this.generateTimeString(endDateTime),
						timezone: this.bookingTime.timezone,
					}
				}

				if (page.type === PAGE_TYPES.WEBINAR && this.sessionTimeSlot) {
					const startDateTime = new Date(this.sessionTimeSlot.startDateTime.replace(/-/g, '/'))
					const endDateTime = new Date(this.sessionTimeSlot.endDateTime.replace(/-/g, '/'))
					sendData.meeting = {
						start: this.generateTimeString(startDateTime),
						end: this.generateTimeString(endDateTime),
						timezone: this.sessionTimeSlot.timezone,
					}
				}

				// If primary product is recurring and order bump selected, separate processing
				if (
					!(this.isPrimaryProductRecurring || this.isStanFlexiblePaymentSelected) &&
					this.secondBuiltInProduct &&
					this.add_upsell_to_order
				) {
					sendData.line_items = `${this.page.page_id},${this.secondBuiltInProduct.page_id}`
				} else if (this.processingSecondProduct) {
					sendData.line_items = `${this.secondBuiltInProduct.page_id}`
				} else {
					sendData.line_items = `${this.page.page_id}`
				}

				sendData.mode = 'stripe-payment-element'
				if (this.activePaymentGatewayName == 'stan-flexible-payment' && !this.processingSecondProduct) {
					sendData.mode = 'stan-flexible-payment-element'
					sendData.payment_gateway = 'stripe' // overwritting the payment gateway for stan flexible payment
				} else if (this.activePaymentGatewayName == 'stan-flexible-payment' && this.processingSecondProduct) {
					sendData.mode = 'stripe-payment-element'
					sendData.payment_gateway = 'stripe'
				}

				if (this.paymentIntent?.subscription) {
					sendData.subscription_id = this.paymentIntent?.subscription
				}

				if (!this.processingSecondProduct) {
					sendData.questions = this.questions.map((question, index) => {
						let answer = this.fanDetails.answers ? this.fanDetails.answers[index] : ''
						if (Array.isArray(answer)) {
							answer = answer.join(', ')
						}
						return { question: question.content, answer }
					})
				}

				if (this.discount_code && this.discount_code_accepted && !this.processingSecondProduct) {
					sendData.discount_code = this.discount_code
				}

				if (this.terms_and_conditions_enabled) {
					sendData.agreed_to_terms_and_conditions = this.isTermsAndConditionChecked
				}

				if (this.salesTaxEnabled) {
					sendData.sales_tax_id = this.salesTax?.id
					sendData.billing_address = this.salesTax?.address
					sendData.sales_tax_breakdown = {
						inclusive: this.salesTax?.tax_breakdown?.inclusive,
						tax_rate_detail_country: this.salesTax?.tax_breakdown?.tax_rate_details?.country,
						tax_rate_detail_percentage_decimal: this.salesTax?.tax_breakdown?.tax_rate_details?.percentage_decimal,
						tax_rate_detail_state: this.salesTax?.tax_breakdown?.tax_rate_details?.state,
						tax_rate_detail_tax_type: this.salesTax?.tax_breakdown?.tax_rate_details?.tax_type,
						taxability_reason: this.salesTax?.tax_breakdown?.taxability_reason,
					}
				}

				return sendData
			},
			calendarInstance() {
				if (process.client && this.page.type === 'meeting' && this.loadAsynCcomponents) {
					return () => import(`~/stan-vue-shared/components/Calendar`)
				} else {
					return ''
				}
			},
			webinarCalendarInstance() {
				if (process.client && this.page.type === PAGE_TYPES.WEBINAR && this.loadAsynCcomponents) {
					return () => import(`~/stan-vue-shared/components/WebinarCalendar`)
				} else {
					return ''
				}
			},
			quantity_text() {
				if (this.isInStock) {
					let item_string = 'item'
					if (this.stockQuantity > 1) item_string = 'items'
					return `${this.stockQuantity} ${item_string} left`
				} else {
					return 'Sold Out'
				}
			},
			terms_and_conditions_enabled() {
				return !!this.user.data.terms_and_conditions?.enabled
			},
			terms_and_conditions() {
				return !!this.terms_and_conditions_enabled ? this.user.data.terms_and_conditions.htmlText : undefined
			},
			activePaymentGatewayCount() {
				return this.paymentGateways.reduce((sum, pg) => (pg.active ? ++sum : sum), 0)
			},
			hasMultiplePaymentGateway() {
				return this.activePaymentGatewayCount > 0
			},
			paymentGatewaysReady() {
				return this.canShowStripe || this.hasMultiplePaymentGateway
			},
			canShowStripe() {
				return !!this.user.connected_payment_methods['stripe']
			},
			canShowPaypal() {
				return !!this.user.connected_payment_methods['paypal']
			},
			paymentGatewayComponent() {
				const activePaymentGatewayRef = this.gatewayRefLookup[this.activePaymentGatewayName]

				const paymentGatewayComponent = Array.isArray(this.$refs[activePaymentGatewayRef])
					? this.$refs[activePaymentGatewayRef][0]
					: this.$refs[activePaymentGatewayRef]
				return paymentGatewayComponent
			},
			fromValidAffiliateShareLink() {
				const lastVisitStore = this.$sessionStorage.getItem('LastVisitStore')
				const affiliateShareLinkRecord = (this.page.data.affiliate_shares || []).find(
					x => x.code === this.affiliateCode && x.status === 'active'
				)
				return (lastVisitStore !== this.user.username && affiliateShareLinkRecord) || this.finalResellRightsUuid
			},
			preSelectedValue() {
				return this.$route.query.selected
			},
			paymentDisabled() {
				return this.isTotalPaid && this.user.payment_restricted.linksite && !this.isResellPage
			},
			paymentDisabledHidden() {
				return this.hasNewProductComingSoon && this.isTotalPaid && this.user.payment_restricted.linksite && !this.isResellPage
			},
			hasNewProductComingSoon() {
				return this.$isFlag('store_product_coming_soon_experiment')
			},
			strikeThruPriceNearCheckout() {
				return this.$isFlag('strikethru_price_near_checkout')
			},
			paymentOrchestrationPhase1() {
				return this.$isFlag('payment_orchestration_phase_1')
			},
			usePaymentOrchestration() {
				return this.paymentOrchestrationPhase1 && !this.isProductRecurring && !this.isStanFlexiblePaymentAvailable
			},
			isPrimaryProductRecurring() {
				return this.page.data.product.price.interval
			},
			isStanFlexiblePaymentAvailable() {
				// Add restrictions and safe gouard for stan flexible payment
				if (this.isPrimaryProductRecurring) {
					return false
				}
				// // Nuxt Store experiment to show only 50% of the time
				// if (!this.$isFlag('store_stan_payment_plans_visible')) {
				// 	return false
				// }
				return !!this.page.data.product.price.stan_flexible_payment?.enabled
			},
			stanFlexiblePaymentPrice() {
				return this.page.data.product.price.stan_flexible_payment
			},
			stanFlexiblePaymentHeader() {
				const grossAmountPerPeriod = this.$formatCurrency(this.grossAmount.toFixed(2), this.user.data.currency)
				const wordMap = {
					day: 'daily',
					week: 'weekly',
					'bi-week': 'biweekly',
					month: 'monthly',
					year: 'annual',
				}

				return `Make ${this.stanFlexiblePaymentPrice?.duration} ${
					wordMap[this.stanFlexiblePaymentPrice?.interval]
				} payments of ${grossAmountPerPeriod}.`
			},
			oneTimeAmount() {
				let grossAmount =
					this.product.price.sale_amount_available && this.product.price.sale_amount > 0
						? this.product.price.sale_amount
						: this.product.price.amount

				if (this.discount_code && this.discount_code_accepted) {
					grossAmount = Math.round(grossAmount * ((100 - this.discount_value) / 100) * 100) / 100
				}
				return grossAmount
			},
			paymentPlanAmount() {
				let grossAmount = this.product.price.stan_flexible_payment?.amount

				if (this.discount_code && this.discount_code_accepted) {
					grossAmount = Math.round(grossAmount * ((100 - this.discount_value) / 100) * 100) / 100
				}
				return grossAmount
			},
			discount_amount() {
				return this.grossAmountPreDiscount - this.grossAmount
			},
			isOrderBumpAvailable() {
				return this.secondBuiltInProduct && !this.fromValidAffiliateShareLink
			},
			showWallet() {
				// For Membership with Orderbump, do not show Wallets
				return !(this.isProductRecurring && this.isOrderBumpAvailable)
			},
			stripeAccountId() {
				return this.user.connected_payment_methods.stripe
			},
			stripeProcessingAccount() {
				return this.user.stripe_processing_account_api_key
			},
			stripeProcessingAccountType() {
				return this.user.stripe_processing_account_type
			},
			isProcessingMarketplace() {
				return this.user.stripe_processing_account_type === 'marketplace'
			},
			isPaymentGatewayDefined() {
				if (this.usePaymentOrchestration) {
					return true
				} else {
					return (
						this.paymentIntent?.id &&
						this.paymentIntent?.id !== '' &&
						this.activePaymentGatewayName &&
						this.activePaymentGatewayName !== ''
					)
				}
			},
			isResellPage() {
				return !!this.page.data.resell_rights_uuid
			},
			finalResellRightsUuid() {
				// look in two places for the uuid, first in the prop (passed through the affiliate/uuid route), then in the page data
				return this.resellRightsUuid || this.page.data.resell_rights_uuid
			},
			finalUsernameOnSuccess() {
				// if the redirectUsername is not sent, use the username from this.user
				return this.redirectUsername || this.user.username
			},
			resellRightsEnabled() {
				return !!this.page.data.resell_rights?.enabled
			},
		},
		created() {
			this.store = { ...this.storeObj }
			if (this.secondProduct) this.secondBuiltInProduct = { ...this.secondProduct }
		},
		async mounted() {
			await loadCaptcha(this.$config.captcha_site_key)
			console.log(this.user)

			this.analyticsProps = {
				...this.trackingProps,
				storeId: this.store.store_id,
				storeType: this.store.type,
				pageId: this.page.page_id,
				pageType: this.page.type,
				order: this.activePage,
				productId: this.page.data.product.id,
				productTitle: this.product.title,
				price: this.grossAmount,
				fanName: this.fanDetails.name,
				fanEmail: this.fanDetails.email,
				// remove below once ready
				product_title: this.product.title,
				product_price: this.grossAmount,
				product_type: this.product.type,
				fan_name: this.fanDetails.name,
				fan_email: this.fanDetails.email,
				store_id: this.store.store_id,
				store_type: this.store.type,
				page_id: this.page.page_id,
				product_id: this.page.data.product.id,
				status: 'completed',
				source: 'store',
				site: 'linkSite',
				tags: { ...this.tags },
			}
			this.sendAnalytic('store-page-view')
			if (this.finalResellRightsUuid) {
				if (this.page.type === 'resell')
					this.sendAnalytic(
						'store-page-view',
						{ resell_rights_uuid: this.finalResellRightsUuid, page_id: this.page.data.affiliate_page_id },
						this.finalUsernameOnSuccess
					)
				this.sendAnalytic('store-affiliate-page-view', { resell_rights_uuid: this.finalResellRightsUuid }, this.finalUsernameOnSuccess)
			}
			if (this.affiliateCode) {
				this.sendAnalytic(
					'store-affiliate-page-view',
					{ affiliateCode: this.affiliateCode },
					this.$route.query?.su || this.$sessionStorage.getItem('LastVisitStore')
				)
			}
			this.isLoaded = true
			const documentStyles = document.documentElement.style

			this.resize = () => {
				this.isDesktop = window.innerWidth >= 1344
			}
			this.resize()
			window.addEventListener('resize', this.resize)

			window.addEventListener('click', this.checkInterceptLink)

			if (this.$cookies.get('autofillQuestionAnswers')) {
				this.autofillQuestionAnswers = this.$cookies.get('autofillQuestionAnswers') ?? {}
				this.sendAnalytic('store-autofill-available')
			}
			this.$axios
				.$get('v1/integrations/stripe/account-capabilities', {
					baseURL: process.env.NUXT_ENV_PYTHON_API,
					params: { page_id: this.page.page_id, resell_rights_uuid: this.finalResellRightsUuid },
				})
				.then(response => {
					this.stripeTaxCapabilities = response
				})

			if (this.affiliateCode) {
				// Analytics to determine what information we have available for affiliate codes to be used in determining the optimal solution
				this.sendAnalytic('affiliate_code_intent_info', {
					session_last_visit_store: this.$sessionStorage.getItem('LastVisitStore'),
					query_username: this.$route.query?.su || null,
					cookie_ref: this.$cookies.get('ref') || null,
					document_ref: window.document.referrer || null,
					affiliate_code: this.affiliateCode,
				})
			}
			if (this.finalResellRightsUuid) {
				// Analytics to determine what information we have available for affiliate codes to be used in determining the optimal solution
				this.sendAnalytic('resell_rights_uuid_intent_info', {
					session_last_visit_store: this.$sessionStorage.getItem('LastVisitStore'),
					query_username: this.$route.query?.su || null,
					cookie_ref: this.$cookies.get('ref') || null,
					document_ref: window.document.referrer || null,
					resell_rights_uuid: this.finalResellRightsUuid,
				})
			}

			this.$nextTick(() => {
				const themeColors = this.$addThemeColorsToDocument(
					this.storeTheme,
					this.userPrimaryColor,
					this.userSecondaryColor,
					this.userPrimaryFont
				)
			})
		},
		beforeDestroy() {
			this.removeScrollListener()
			window.removeEventListener('resize', this.resize)
			window.removeEventListener('click', this.checkInterceptLink)
		},
		updated: debounce(function () {
			this.loadAsynCcomponents = true
			if (!this.fcpComplete) {
				this.$nextTick(() => {
					this.fcpComplete = true
					setTimeout(() => {
						this.paymentCardViewed = true
					}, 500)
				})
			}
		}, 200),
		methods: {
			generateTimeString(date) {
				const year = date.getFullYear()
				const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)
				const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
				const time = new Intl.DateTimeFormat('en-US-u-hc-h23', { hour: '2-digit', minute: 'numeric', second: 'numeric' }).format(date)
				return `${year}-${month}-${day} ${time}`
			},
			setFanDetails(fanDetails) {
				this.fanDetails = fanDetails
			},
			setAnswers(answers) {
				this.fanDetails.answers = answers
			},
			setNote(note) {
				this.fanDetails.note = note
			},
			sendAnalytic(name, additional_props = {}, username) {
				const analyticsPropsUse = { ...this.analyticsProps, ...additional_props }
				this.$stanAnalytics(name, {
					meta: { username: username || this.user.username },
					props: analyticsPropsUse,
				})
			},
			validateForm(check = true) {
				this.$refs.inputForm.formIssues(check)
			},
			setFanDetailsValid(formIsValid) {
				this.isFanDetailsValid = formIsValid
			},
			bookingTimeSelected(timeSlot) {
				this.bookingTime = timeSlot.slot
				this.bookingDuration = timeSlot.duration
			},
			sessionTimeSelected(timeSlot) {
				this.sessionTimeSlot = timeSlot
			},
			checkInterceptLink(event) {
				var target = event.target.closest('a[href]')
				if (target) {
					event.preventDefault()
					const openNewPage = target.getAttribute('target') === '_blank'
					this.openLink(target.getAttribute('href'), openNewPage)
				}
			},
			openLink(url, newPage = false) {
				// Check if stan affiliate and append username
				let linkUrl = null
				try {
					linkUrl = new URL(url)
				} catch (e) {
					return (window.location.href = url)
				}

				linkUrl = this.$helpers.appendCurrentUrlParamsToUrl(linkUrl)

				const frontendURI = process.env.NUXT_ENV_FRONT_URL.replace('www.', '')
				if (linkUrl && linkUrl.href.includes(frontendURI) && !!linkUrl.searchParams.get('al')) {
					linkUrl.searchParams.set('su', this.user.username)

					// Analytics to determine what information we have available for affiliate codes to be used in determining the optimal solution
					this.sendAnalytic('affiliate_code_link_clicked_from_product_page', {
						session_last_visit_store: this.$sessionStorage.getItem('LastVisitStore'),
						query_username: this.$route.query?.su || null,
						cookie_ref: this.$cookies.get('ref') || null,
						document_ref: window.document.referrer || null,
						affiliate_code: linkUrl.searchParams.get('al'),
						affiliated_username: linkUrl.searchParams.get('su'),
					})
				}

				if (newPage) {
					return window.open(linkUrl, '_blank')
				}
				window.location.href = linkUrl
			},
			handleGateway(data) {
				if (data.name === 'stripe') {
					if (!this.activePaymentGatewayName) this.setActivePaymentGateway(data.name)
					this.isStripeConnect = data.isConnect
				} else if (data.name === 'stripe2') {
					if (!this.activePaymentGatewayName) this.setActivePaymentGateway(data.name)
					this.isStripeConnect = true
				} else if (data.name === 'stan-flexible-payment') {
					if (!this.activePaymentGatewayName) this.setActivePaymentGateway(data.name)
					this.isStripeConnect = data.isConnect
				} else {
					const paymentGateway = this.paymentGateways.find(pm => pm.name == data.name)
					paymentGateway.data = data.data

					if (paymentGateway.canActivate(data.data) && data.available) {
						paymentGateway.active = true
						if (!this.activePaymentGatewayName) this.setActivePaymentGateway(data.name)
					} else {
						paymentGateway.active = false
					}
				}
				this.paymentGatewaysInitialised++
			},
			async paymentDataReceived(paymentData) {
				if (paymentData.name == this.activePaymentGatewayName) this.paymentIntent = paymentData.data
				if (paymentData && this.processingSecondProduct && this.paymentIntent?.id === paymentData.data.id) {
					if (!this.secondPaymentDataUpdated) {
						// Update Payment Intent with second product
						this.paymentGatewayComponent.updatePaymentData(this.purchaseIntentPayload)
						this.secondPaymentDataUpdated = true
					} else {
						const payload = { ...this.purchaseIntentPayload, order_bump: true, order_bump_parent_page: this.page.page_id }
						try {
							const response = await this.$axios.post('v1/orders/purchase-intent', payload, {
								baseURL: process.env.NUXT_ENV_PYTHON_API,
							})
							this.auth_token = response.data.auth_token
							if (this.secondBuiltInProduct.type === 'course' && response.data.course_fan_slug)
								this.secondBuiltInProduct.data.product.course.slug = response.data.course_fan_slug
							this.$nextTick(() => {
								this.paymentGatewayComponent.processPayment()
							})
						} catch {
							this.$notify({
								group: '1',
								title: 'Something went wrong',
								text: 'Please email friends@stanwith.me for help.',
								type: 'error',
							})
							this.sendAnalytic('store-payment-error', { errorCodes: [PAGE_ERROR_CODES.PAYMENT_ERROR_RECEIVED_BLOCK] })
						}
					}
				}
			},
			async getProductDetails(page) {
				try {
					grecaptcha.enterprise.ready(async () => {
						const token = await grecaptcha.enterprise.execute(this.$config.captcha_site_key, {
							action: 'FreeProductCompletionIntent',
						})
						this.purchaseIntentPayload.token = token
						const response = await this.$axios.post('v1/pages/completed/' + page.type, this.purchaseIntentPayload)
						this.auth_token = response.data.auth_token
						if (page.type === 'course' && response.data.course_fan_slug) page.data.product.course.slug = response.data.course_fan_slug
					})
				} catch (err) {
					if (!this.checkProcess409Error(err)) {
						console.error(err)
					}
					return false
				}

				const product = page.data.product
				if (this.auth_token) {
					this.$cookies.set('auth_token', this.auth_token)
					this.$store.commit('setAuthToken', this.auth_token)
				}

				if (this.discount_code && this.discount_code_accepted) {
					product.price.sale_amount_available = true

					this.analyticsProps.discount_code = this.discount_code
				}

				if (page.type === 'meeting') {
					product.meeting_id = product.id
					product.start = this.bookingTime.date + ' ' + this.bookingTime.startTime
					product.end = this.bookingTime.date + ' ' + this.bookingTime.endTime
					product.timezone = this.bookingTime.timezone
				}
				if (page.type === PAGE_TYPES.WEBINAR) {
					product.meeting_id = product.id
					product.start = this.sessionTimeSlot.startDateTime
					product.end = this.sessionTimeSlot.endDateTime
					product.timezone = this.sessionTimeSlot.timezone
				}
				if (page.type === 'course') {
					product.course_url = `/course/${product.course.slug}?store=${this.store.slug}`
				}
				return product
			},
			async paymentElementSuccess(page = this.page) {
				if (!(this.processingSecondProduct ? this.isTotalPaid : this.isPaid)) {
					try {
						await new Promise((resolve, reject) => {
							grecaptcha.enterprise.ready(async () => {
								try {
									const token = await grecaptcha.enterprise.execute(this.$config.captcha_site_key, {
										action: 'FreeProductCompletionIntent',
									})
									this.purchaseIntentPayload.token = token
									const response = await this.$axios.post('v1/pages/completed/' + this.page.type, this.purchaseIntentPayload)

									this.auth_token = response.data.auth_token
									if (this.page.type === 'course' && response.data.course_fan_slug)
										this.page.data.product.course.slug = response.data.course_fan_slug

									if (page.type === 'community') this.page.data.product.magic_link = response.data.magic_link
									resolve()
								} catch (err) {
									reject(err)
								}
							})
						})

						const product = page.data.product
						if (this.auth_token) {
							this.$cookies.set('auth_token', this.auth_token)
							this.$store.commit('setAuthToken', this.auth_token)
						}
						if (this.discount_code && this.discount_code_accepted) {
							product.price.sale_amount_available = true
						}

						if (page.type === 'meeting') {
							product.meeting_id = product.id
							product.start = this.bookingTime.date + ' ' + this.bookingTime.startTime
							product.end = this.bookingTime.date + ' ' + this.bookingTime.endTime
							product.timezone = this.bookingTime.timezone
						}
						if (page.type === PAGE_TYPES.WEBINAR) {
							product.meeting_id = product.id
							product.start = this.sessionTimeSlot.startDateTime
							product.end = this.sessionTimeSlot.endDateTime
							product.timezone = this.sessionTimeSlot.timezone
						}
						if (page.type === 'course') {
							product.course_url = `/course/${product.course.slug}?store=${this.store.slug}`
						}

						this.purchasedProducts.push(product)
					} catch (err) {
						if (!this.checkProcess409Error(err)) {
							console.error(err)
							this.$notify({
								group: '1',
								title: 'Something went wrong',
								text: 'Please email friends@stanwith.me for help.',
								type: 'error',
							})
							this.sendAnalytic('store-payment-error', { errorCodes: [PAGE_ERROR_CODES.PAYMENT_ERROR_SUCCESS_BLOCK] })
						}
						return false
					}
				}

				let orderAmount = this.grossAmount

				if (
					!(this.isPrimaryProductRecurring || this.isStanFlexiblePaymentSelected) &&
					this.activePage === 0 &&
					this.secondBuiltInProduct &&
					this.add_upsell_to_order
				) {
					this.analyticsProps.order_bump = true
					this.analyticsProps.order_bump_parent_page = this.page.page_id

					orderAmount += this.secondProductGrossAmount
				}

				// Only for membership with order bump
				if (this.processingSecondProduct) {
					orderAmount = this.secondProductGrossAmount
					this.analyticsProps.order_bump = true
					this.analyticsProps.order_bump_parent_page = this.page.page_id
				}

				this.analyticsProps.pageId = page.page_id
				this.sendAnalytic('purchase')
				!isEmpty(this.tags) && this.sendAnalytic('purchase-tagged')

				if (this.user.data?.analytics?.facebook?.token && this.$fb) {
					this.$fb.track('Purchase', {
						currency: this.user.data.currency,
						value: orderAmount,
					})
				}
				if (this.user.data?.analytics?.tiktok?.token && this.$tt) {
					this.$tt.track('CompletePayment', {
						currency: this.user.data.currency,
						value: orderAmount,
					})
				}
				this.questions.forEach((question, index) => {
					this.cachedQuestionAnswers[question.content] = this.fanDetails.answers[index]
				})

				const oldCachedAnswers = this.$cookies.get('autofillQuestionAnswers') ?? {}
				if (oldCachedAnswers) {
					const country_code = this.cachedQuestionAnswers['Phone Number']
						? this.fanDetails.phone_number.replace(this.cachedQuestionAnswers['Phone Number'], '')
						: ''
					this.autofillQuestionAnswers = {
						...oldCachedAnswers,
						...this.cachedQuestionAnswers,
						...{
							name: this.fanDetails.name,
							email: this.fanDetails.email,
							countryCode: country_code,
						},
					}
				}
				this.$cookies.set('autofillQuestionAnswers', this.autofillQuestionAnswers, {
					path: '/',
					maxAge: 60 * 60 * 24 * 365,
				})

				if (!this.isPaid) {
					if (
						page.type === 'lead-magnet' &&
						page.data.product?.lead_magnet?.digital_assets_type === 'url' &&
						page.data.product?.lead_magnet?.digital_assets?.length > 0 &&
						!!page.data.product?.lead_magnet?.digital_assets[0]?.url
					) {
						this.openLink(page.data.product?.lead_magnet?.digital_assets[0]?.url)
					} else {
						this.$store.commit('setPurchasedProducts', this.purchasedProducts)
						this.$router.push('/' + this.finalUsernameOnSuccess + '/success')
					}
				} else if (
					(this.isPrimaryProductRecurring || this.isStanFlexiblePaymentSelected) &&
					this.activePage === 0 &&
					this.secondBuiltInProduct &&
					this.add_upsell_to_order &&
					!this.processingSecondProduct
				) {
					this.processingSecondProduct = true
					this.purchasedPaymentIntents.push(this.paymentIntent)
					this.paymentGatewayComponent.createPaymentData(this.secondBuiltInProduct.page_id)
				} else {
					if (!this.usePaymentOrchestration) {
						this.add_upsell_to_order = false
						this.purchasedPaymentIntents.push(this.paymentIntent)

						const ids = this.purchasedPaymentIntents.map(intent => intent.id)
						const stringifiedIds = ids.join(',') // can also do just ids.join()

						// Navigate to Success page with payment intent id
						this.$router.push(`/${this.finalUsernameOnSuccess}/success/?id=${stringifiedIds}`)
					} else {
						this.$router.push(`/${this.finalUsernameOnSuccess}/success/?order_id=${this.orderId}`)
					}
				}
			},
			setActivePaymentGateway(paymentGateway) {
				this.activePaymentGatewayName = paymentGateway
				this.activePaymentGateway = this.paymentGateways.find(pm => pm.name == paymentGateway)
				this.paymentIntent = this.paymentGatewayComponent.paymentData
			},
			paymentCancel() {
				this.formSubmitting = false
				this.paymentInitialised = false
			},
			paymentError(errorData) {
				this.formSubmitting = false
				this.paymentInitialised = false

				this.$notify({
					group: '1',
					title: 'Payment error',
					text: errorData.message || 'Please complete the payment form!',
					type: 'error',
				})
				this.sendAnalytic('store-payment-error', { errorCodes: [PAGE_ERROR_CODES.PAYMENT_ERROR] })
			},
			async applyDiscountCode() {
				const findInvalidCharacter = /[^A-Za-z0-9]+/
				this.discount_processing = true
				if (this.discount_code && this.isPaymentGatewayDefined) {
					if (findInvalidCharacter.test(this.discount_code)) {
						this.discount_error = 'Invalid discount code'
						this.discount_processing = false
					} else {
						const payload = {
							discount_code: this.discount_code,
							page_id: this.page.page_id,
							username: this.user.username,
							payment_intent_id: this.paymentIntent?.id,
							payment_gateway: this.activePaymentGatewayName,
						}

						const testDiscountCode = async payload => {
							try {
								return await this.$axios.post('v1/pages/discount-code', payload, { baseURL: process.env.NUXT_ENV_PYTHON_API })
							} catch (err) {
								console.error('discount code error: ', err)
								this.discount_error =
									err.response && err.response.data ? err.response.data.message : 'Something went wrong. Try again later'
								this.discount_processing = false
								return null
							}
						}
						const resp = await testDiscountCode(payload)
						if (resp !== null) {
							this.discount_code_accepted = true

							this.discount_type = resp.data.discount_type
							this.discount_value = resp.data.discount_value
							this.discount_duration = resp.data.discount_duration

							this.discount_processing = false
							this.analyticsProps.discount_code = this.discount_code

							this.calculateSalesTax()
							return resp
						} else {
							return null
						}
					}
				} else {
					this.discount_error = 'Did you forget to include your discount code?'
					this.discount_processing = false
				}
			},
			async clearDiscountCode(paymentGateway = this.activePaymentGatewayName) {
				this.discount_code = ''
				this.discount_error = ''
				this.discount_code_accepted = false
				this.discount_value = 0
				this.discount_duration = 0

				this.calculateSalesTax()
			},
			canSubmit() {
				let codes = []
				let invalidData = []
				const formIssues = this.$refs.inputForm.formIssues()

				if (this.page.type === 'meeting') {
					if (!this.bookingTime) {
						codes.push(PAGE_ERROR_CODES.BOOKING_TIME)
					}
					if (!this.bookingTime.date) {
						codes.push(PAGE_ERROR_CODES.BOOKING_DATE)
					}
					if (!this.bookingTime.startTime) {
						codes.push(PAGE_ERROR_CODES.BOOKING_START_TIME)
					}
					if (!this.bookingTime.endTime) {
						codes.push(PAGE_ERROR_CODES.BOOKING_END_TIME)
					}
				}

				if (this.page.type === PAGE_TYPES.WEBINAR) {
					if (!this.$refs['webinar-calendar'].hasAvailableSession) {
						codes.push(PAGE_ERROR_CODES.WEBINAR_NO_TIMES_AVAILABLE)
					}
					if (!this.sessionTimeSlot) {
						codes.push(PAGE_ERROR_CODES.WEBINAR_TIME_NOT_SELECTED)
					}
					if (!this.isFanDetailsValid) {
						codes.push(PAGE_ERROR_CODES.WEBINAR_MISSING_DETAILS)
					}
				}

				if (!this.fanDetails.name) {
					codes.push(PAGE_ERROR_CODES.FAN_NAME)
				}
				if (!this.fanDetails.email) {
					codes.push(PAGE_ERROR_CODES.FAN_EMAIL)
				}
				if (!this.isInStock) {
					codes.push(PAGE_ERROR_CODES.NO_STOCK)
				}

				if (!!this.terms_and_conditions) {
					if (!this.isTermsAndConditionChecked) {
						codes.push(PAGE_ERROR_CODES.ACCEPT_TC)
					}
				}

				// Check tax address was valid and we were able to calculate tax
				if (this.salesTaxEnabled && !this.salesTax?.id) {
					invalidData.push({ invalidTaxAddress: this.$refs.inputForm.address })
					this.$refs.inputForm.validateAddress()
					codes.push(PAGE_ERROR_CODES.TAX)
				}
				// Check discount codes
				if (this.discount_code) {
					if (!this.discount_code_accepted && !this.discount_error) {
						invalidData.push({
							invalidDiscountCode: { code: this.discount_code, accepted: this.discount_code_accepted, error: this.discount_error },
						})
						codes.push(PAGE_ERROR_CODES.UNAPPLIED_DC)
					}
				}
				if (formIssues.length > 0) {
					for (const issue of formIssues) {
						invalidData.push({ [issue.error]: issue.data })
						switch (issue.error) {
							case 'invalidName':
								codes.push(PAGE_ERROR_CODES.INVALID_NAME)
								break
							case 'invalidEmail':
								codes.push(PAGE_ERROR_CODES.INVALID_EMAIL)
								break
							case 'invalidQuestion':
								!codes.includes('I03') && codes.push(PAGE_ERROR_CODES.INVALID_QUESTION)
								break
						}
					}
				}
				if (this.formSubmitting) {
					codes.push(PAGE_ERROR_CODES.FORM_SUBMITTING)
				}

				// TODO: REMOVE TUESDAY
				// Checking if cache issue -> if we find that filtered codes !== codes, cache issue found (code not found in PAGE_ERROR_CODES).
				const filterCodes = codes.filter(val => val)
				if (filterCodes.length !== codes.length) {
					this.sendAnalytic('store-null-error-code-found', { errorCodeDefinition: PAGE_ERROR_CODES })
				}
				return [codes, invalidData]
			},
			showSubmitError(errorCodes, invalidData = []) {
				// only show the top error code
				let topErrorCode = errorCodes[0]
				let errorCodeObject = CODE_TO_ERROR_MESSAGE[topErrorCode]
				let text = 'Please complete all required fields!'
				let title = 'Form incomplete'
				let type = 'error'
				const additionalProps = { fanDetails: this.fanDetails }
				invalidData.length > 0 && (additionalProps.invalidData = invalidData)
				if (!errorCodeObject) {
					this.sendAnalytic('store-code-mismatch-error', { codeGiven: topErrorCode, ...additionalProps })
				} else {
					text = errorCodeObject.text
					title = errorCodeObject.title
					type = errorCodeObject.type
				}

				// Track submission errors to see where customers are getting stuck
				console.log('submission error', { errorCodes })
				this.sendAnalytic('store-submit-error', { errorCodes, ...additionalProps })

				this.$notify({
					group: '1',
					title: title,
					text: text,
					type: type,
				})
			},
			checkShowSubmitError() {
				const [errorCodes, invalidData] = this.canSubmit()
				if (errorCodes.length > 0) {
					this.showSubmitError(errorCodes, invalidData)
				}
			},
			checkProcess409Error(err) {
				if (err.response && err.response.status == 409) {
					let title = 'This item is no longer available'
					let text = 'Please try again later'
					let errorCode = PAGE_ERROR_CODES.NO_STOCK
					if (this.page.type == PAGE_TYPES.MEETING || this.page.type == PAGE_TYPES.WEBINAR) {
						title = 'This slot is not available'
						text = 'Please select another slot'
						errorCode = PAGE_ERROR_CODES.BOOKING_TAKEN
						// Reload calendar dates
						if (this.page.type == PAGE_TYPES.MEETING) {
							this.bookingTime = false
							this.bookingDuration = 0
							this.$refs['calendar'].reset()
						}

						if (this.page.type == PAGE_TYPES.WEBINAR) {
							this.sessionTimeSlot = undefined
							this.$refs['webinar-calendar'].reset()
						}
					}
					this.$notify({
						group: '1',
						title,
						text,
						type: 'warn',
					})
					this.sendAnalytic('store-submit-error', { errorCodes: [errorCode] })
					this.formSubmitting = false
					return true
				}
				return false
			},
			async triggerSubmit(paymentGateway) {
				const [errorCodes, invalidData] = this.canSubmit()
				if (errorCodes.length > 0) {
					this.paymentCancel()
					this.showSubmitError(errorCodes, invalidData)
					return false
				} else {
					this.formSubmitting = true
					if (this.isPaid) {
						try {
							if (paymentGateway === 'stripe2' || paymentGateway === 'paypal2') {
								// new stripe payment element
								// highly doubt all this shit is needed
								this.setActivePaymentGateway(paymentGateway)
								const activePaymentGatewayRef = this.gatewayRefLookup[this.activePaymentGatewayName]

								const paymentGatewayComponent = Array.isArray(this.$refs[activePaymentGatewayRef])
									? this.$refs[activePaymentGatewayRef][0]
									: this.$refs[activePaymentGatewayRef]

								const response = await this.$axios.post('v1/orders/create-order-intent', this.purchaseIntentPayload, {
									baseURL: process.env.NUXT_ENV_PYTHON_API,
								})
								console.log(response)
								this.orderId = response.data.order_id
								if (!this.orderId) {
									this.$sentry.captureException(new Error('Could not create order'), {
										extra: {
											response: response,
											purchaseIntentPayload: this.purchaseIntentPayload,
										},
									})
								}
								await paymentGatewayComponent.processPayment(this.orderId) // TODO: change this to update a variable instead.

								return true
							} else {
								this.setActivePaymentGateway(paymentGateway)
								const activePaymentGatewayRef = this.gatewayRefLookup[this.activePaymentGatewayName]

								const paymentGatewayComponent = Array.isArray(this.$refs[activePaymentGatewayRef])
									? this.$refs[activePaymentGatewayRef][0]
									: this.$refs[activePaymentGatewayRef]

								await paymentGatewayComponent.updatePaymentData(this.purchaseIntentPayload)
								await this.$nextTick()

								const response = await this.$axios.post('v1/orders/purchase-intent', this.purchaseIntentPayload, {
									baseURL: process.env.NUXT_ENV_PYTHON_API,
								})
								this.auth_token = response.data.auth_token
								if (this.page.type === 'course' && response.data.course_fan_slug)
									this.page.data.product.course.slug = response.data.course_fan_slug
								paymentGatewayComponent.processPayment()

								return true
							}
						} catch (error) {
							if (!this.checkProcess409Error(error)) {
								console.error(error)
								this.formSubmitting = false
								this.paymentInitialised = false
								const errorMessageData = {
									group: '1',
									title: 'Something went wrong',
									text: 'Please try again later. If problem persists, reach out to us at <a href="mailto:friends@stanwith.me">friends@stanwith.me</a>',
									type: 'error',
								}
								if (error.response && error.response?.data?.code === 'purchase_temp_block') {
									errorMessageData.title = 'Currently Unavailable'
									errorMessageData.text =
										'This product is on a temporary hold due to limited availability, please try again in 3 minutes.'
								} else {
									// Capture error in Sentry only if it's not a purchase_temp_block error
									this.$sentry.captureException(new Error('Could not create purchase-intent'), {
										extra: {
											error,
											purchaseIntentPayload: this.purchaseIntentPayload,
										},
									})
								}
								this.$notify(errorMessageData)
							}
							return false
						}
					} else {
						await this.paymentElementSuccess()
						return true
					}
				}
			},
			goBack() {
				if (this.$router.history.length > 1) {
					this.$router.back()
				} else {
					this.$router.push(`/${this.user.username}`)
				}
			},
			nextStep() {
				const totalProducts = this.secondBuiltInProduct ? 2 : 1
				if (this.activePage === totalProducts - 1) {
					this.$store.commit('setPurchasedProducts', this.purchasedProducts)
					this.$router.push('/' + this.finalUsernameOnSuccess + '/success')
				} else {
					this.activePage++
				}
			},
			termsAndConditionUpdated(val) {
				this.isTermsAndConditionChecked = val
			},
			calculateSalesTax: debounce(function () {
				this.salesTax = {}
				if (this.salesTaxEnabled && this.fanDetails.address.country) {
					const payload = {
						page_id: this.page.page_id,
						country: this.fanDetails.address.country,
						state: this.fanDetails.address.state,
						city: this.fanDetails.address.city,
						postal_code: this.fanDetails.address.postal_code,
						street: this.fanDetails.address.street,
						amount: this.grossMultiProductAmount,
						currency: this.user.data.currency,
					}
					this.$axios
						.$post('v1/integrations/calculate-sales-tax', payload, {
							baseURL: process.env.NUXT_ENV_PYTHON_API,
						})
						.then(response => {
							this.salesTax = response
						})
				}
			}, 200),
			oneTimePayment() {
				this.isStanFlexiblePaymentSelected = false
				this.sendAnalytic('store-pay-now-button-clicked')
			},
			stanFlexiblePayment() {
				this.isStanFlexiblePaymentSelected = true
				this.sendAnalytic('store-payment-plan-selected')
			},
			getPaymentGatewayComponent(componentName) {
				const activePaymentGatewayRef = this.gatewayRefLookup[componentName]
				const paymentGatewayComponent = Array.isArray(this.$refs[activePaymentGatewayRef])
					? this.$refs[activePaymentGatewayRef][0]
					: this.$refs[activePaymentGatewayRef]
				return paymentGatewayComponent
			},
			updateAmountToPaymentIntent: debounce(async function () {
				this.$nextTick(async () => {
					if (this.isTotalPaid) {
						if (!this.isStanFlexiblePaymentSelected) {
							// For One time price
							const stripePaymentElement = this.getPaymentGatewayComponent('stripe')
							let payload = {
								amount: this.totalAmount * 100,
								payment_intent_id: stripePaymentElement.paymentData.id,
								stripe_processing_account_type: this.stripeProcessingAccountType,
							}

							await this.$axios.post('v1/pages/update-payment-intent', payload, { baseURL: process.env.NUXT_ENV_PYTHON_API })
						}
					}
				})
			}, 400),
			handleReviewModalOpen(isOpen) {
				if (isOpen) {
					this.removeScrollListener()
				} else {
					this.attachScrollListener()
				}
			},
			attachScrollListener() {
				if (this.listenerAttached) {
					return
				}

				this.$nextTick(() => {
					const storeHeader = this.$refs.storeHeader?.$el || this.$refs.storeHeader
					const storeContent = this.$refs.storeContent

					if (storeHeader && storeContent) {
						this.scrollHandler = e => {
							storeContent.scrollTop += e.deltaY
						}

						storeHeader.addEventListener('wheel', this.scrollHandler)

						this.listenerAttached = true
					} else {
						console.warn('storeHeader or storeContent not found')
					}
				})
			},
			removeScrollListener() {
				if (this.listenerAttached) {
					const storeHeader = this.$refs.storeHeader?.$el || this.$refs.storeHeader
					if (storeHeader && this.scrollHandler) {
						storeHeader.removeEventListener('wheel', this.scrollHandler)
					}
					this.listenerAttached = false
				}
			},
		},
		watch: {
			activePage(newValue) {
				this.clearDiscountCode()
				this.$nextTick(() => {
					if ('pageWrapper' in this.$refs) {
						setTimeout(() => (this.$refs.pageWrapper.scrollTop = 0), 500)
					}
				})
			},
			'page.page_id'(newPage, oldPage) {
				if (this.isPaid) {
					this.createPaymentIntent()
				}
			},
			isTotalPaid(isTotalPaid) {
				if (isTotalPaid) {
					this.$nextTick(() => {
						if (typeof this.$refs.stripePaymentElement !== 'undefined' && this.$refs.stripePaymentElement.stripeReady === false) {
							this.$refs.stripePaymentElement.initStripe()
						}
						if (
							typeof this.$refs.stanFlexiblePaymentElement !== 'undefined' &&
							this.$refs.stanFlexiblePaymentElement.stripeReady === false
						) {
							this.$refs.stanFlexiblePaymentElement.initStripe()
						}
					})
				}
			},
			add_upsell_to_order() {
				this.calculateSalesTax()
			},
			fanDetails: {
				deep: true,
				handler(newVal) {
					if (this.salesTaxEnabled && newVal.address) {
						if (
							newVal.address?.city !== '' &&
							newVal.address?.state !== '' &&
							newVal.address?.postal_code !== '' &&
							newVal.address?.country !== ''
						) {
							// Calculate Tax
							this.calculateSalesTax()
						}
					}
				},
			},
			totalAmount() {
				// Update Payment Intent
				this.updateAmountToPaymentIntent()
			},
			isDesktop(newVal) {
				if (newVal && this.isLoaded) {
					this.attachScrollListener()
				} else {
					this.removeScrollListener()
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	@import '~/stan-vue-shared/assets/styles/themes/index.scss';
	@import '~/stan-vue-shared/assets/styles/themes/store-page.scss';
	@import '~/stan-vue-shared/assets/styles/themes/responsive/index.scss';
	@import '~/stan-vue-shared/assets/styles/themes/pages/responsive/index.scss';

	.content-disabled {
		pointer-events: none;
		opacity: 0.4;
	}
	.strikethru-container {
		margin-bottom: 10px;
	}
	.price-component {
		margin-bottom: 10px;
	}
	.coming-soon-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 40px;
		gap: 8px;
		border: 1px solid var(--stan-store-theme-primary-color);
		border-radius: 8px;
		margin-inline: 20px;
		margin-block: 20px;
		p {
			margin: 0;
		}
		.coming-soon-header {
			font-weight: 600;
			font-size: 18px;
		}
		.coming-soon-body {
			font-size: 14px;
			text-align: center;
		}

		.savings {
			font-style: normal;
			font-weight: bold;
			font-size: 23px;
			line-height: 130%;
			order: 3;
			flex: 0 0 auto;
			width: auto;
			max-width: 100%;
			color: #cad1e1;
		}
	}
</style>
<style lang="scss">
	.ProseMirror {
		iframe {
			border-radius: 1rem !important;
		}
	}
</style>
<style>
	.grecaptcha-badge {
		display: none !important;
	}
</style>

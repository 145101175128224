<template>
	<div>
		<div class="pages-block px-5-mod relative" key="total-block">
			<div class="tax-amount-wrapper">
				<span>
					<span class="d-flex justify-content-between align-items-center w-100 tax-amount-wrapper-line">
						<span class="subtotal pr-2">Subtotal :</span>
						<div class="subtotal-am pl-2" :style="{ color: userPrimaryColor }">
							{{ $formatCurrency(subTotalAmount, currency, 'en-US', true) }}
						</div>
					</span>
					<span
						class="d-flex justify-content-between align-items-center w-100 tax-amount-wrapper-line"
						v-if="nonDiscountedPrice !== grossMultiProductAmount && strikeThruPriceNearCheckout"
					>
						<span class="tax pr-2">Discount :</span>
						<div class="tax-am pl-2" :style="{ color: userPrimaryColor }">
							{{
								$formatCurrency(
									grossAmountPreDiscountCode - grossMultiProductAmount + discountedPriceSavings,
									currency,
									'en-US',
									true
								)
							}}
						</div>
					</span>
					<span class="d-flex justify-content-between align-items-center w-100 tax-amount-wrapper-line">
						<span class="tax pr-2">Tax :</span>
						<div class="tax-am pl-2" :style="{ color: userPrimaryColor }" v-if="hasSalesTaxData">
							{{ $formatCurrency(salesTaxAmount, currency, 'en-US', true) }}
						</div>
						<div class="tax-am pl-2" :style="{ color: userPrimaryColor }" v-else>--</div>
					</span>
					<span class="divide-bar d-flex justify-content-between align-items-center w-100 tax-amount-wrapper-line"></span>
					<span class="d-flex justify-content-between align-items-center w-100 tax-amount-wrapper-line">
						<span class="total pr-2">Total :</span>
						<div class="total-am pl-2" :style="{ color: userPrimaryColor }">
							{{ $formatCurrency(totalAmount, currency, 'en-US', true) }}
						</div>
					</span>
				</span>
			</div>
			<div v-if="discount_duration" key="duration-block" class="text-right">
				for {{ discount_duration }} month(s), {{ $formatCurrency(grossAmountPreDiscountWithTax, currency) }} afterwards
			</div>
			<div v-if="product.price.interval && product.price.membership_duration" key="duration-block" class="text-right">
				Payments will automatically end after {{ product.price.membership_duration }} period(s)
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		watch: {},
		name: 'SalesTax',
		props: {
			address: { type: Object, default: () => {} },
			grossAmountPreDiscountCode: { type: Number, default: 0 },
			nonDiscountedPrice: { type: Number, default: 0 },
			grossMultiProductAmount: { type: Number, default: 0 },
			discount_duration: { type: Number, default: 0 },
			product: { type: Object, default: () => {} },
			currency: { type: String, default: 'USD' },
			salesTax: { type: Object, default: () => {} },
			userPrimaryColor: { type: String },
		},
		mounted() {
			console.log('address', this.$store.getters['getIPAddress'])
		},
		computed: {
			salesTaxAmount() {
				if (this.salesTax && this.salesTax?.tax_breakdown?.amount) {
					if (this.isSalesTaxInclusive) {
						return this.subTotalAmount * (this.salesTaxPercentage / (1 + this.salesTaxPercentage))
					}
					return this.subTotalAmount * this.salesTaxPercentage
				}
				return 0
			},
			discountedPriceSavings() {
				if (this.nonDiscountedPrice === this.grossAmountPreDiscountCode) {
					return 0
				}
				return this.nonDiscountedPrice - this.grossAmountPreDiscountCode
			},
			hasSalesTaxData() {
				return this.salesTax && this.salesTax.tax_breakdown
			},
			isSalesTaxInclusive() {
				return this.salesTax && this.salesTax?.tax_breakdown?.inclusive
			},
			totalAmount() {
				if (this.isSalesTaxInclusive) {
					return this.grossMultiProductAmount
				}
				return this.grossMultiProductAmount + this.salesTaxAmount
			},
			subTotalAmount() {
				return this.nonDiscountedPrice
			},
			strikeThruPriceNearCheckout() {
				return this.$isFlag('strikethru_price_near_checkout')
			},
			salesTaxPercentage() {
				return (
					Number(
						this.salesTax && this.salesTax?.tax_breakdown?.tax_rate_details?.percentage_decimal
							? this.salesTax?.tax_breakdown?.tax_rate_details?.percentage_decimal
							: 0
					) / 100
				)
			},
			grossAmountPreDiscountWithTax() {
				if (this.salesTax && this.salesTax?.tax_breakdown?.amount) {
					if (!this.isSalesTaxInclusive) {
						return this.grossAmountPreDiscountCode + this.grossAmountPreDiscountCode * this.salesTaxPercentage
					}
				}
				return this.grossAmountPreDiscountCode
			},
		},
	}
</script>
<style lang="scss" scoped></style>
